export default {
    props: ['provisioners'],
    data() {
        return {
            form:{
                quantity:0,
                reason: '',
            }
        }
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        confirmWriteOff() {
            this.$emit('confirmWriteOff', this.form)
        },
        cancelRemove() {
            this.$emit('closePopup')
        }
    }
}