import {mapGetters, mapActions} from 'vuex';

const _ = require('lodash')

export default {
    props: ['popupType'],
    data() {
        return {
            form: {
                buy_quantity: null,
                buy_price: null,
                buy_price_usd: null,
                less_quantity: null
            },
            category_id: null,
            product_id: null,
            product_price: null,
            categoryList: null,
        }
    },
    computed: {
        ...mapGetters({
            products: 'catalog/products',
            product: 'catalog/product',
            productCategories: 'catalog/productCategories'
        }),
    },
    watch: {
        productCategories(e){
            this.categoryList = _.clone(e.data);
            this.categoryList.map(item=>{
                if(item.children.length){
                    item.children.map(childMap=>{
                        this.categoryList.push(childMap);
                    })
                }
            })
        },
        category_id(e) {
           this.getProductsByCategory(e)
        },
        product_id(e) {
            this.showProduct(e)
        },
        product(e) {
            this.form.buy_price = Number(e.discount_price).toFixed(2);
            this.form.buy_price_usd = Number(e.current_buy_price_in_usd).toFixed(2);


           this.product_id = e.id
           this.product_price = Number(e.sale_price).toFixed(2)
        }
    },
    created() {
        this.getProductCategories()
    },
    methods: {
        createProduct() {
            const payload = {
                buy_price_usd: Number(this.form.buy_price_usd).toFixed(2),
                buy_price: Number(this.form.buy_price).toFixed(2),
                buy_quantity: +this.form.buy_quantity,
                less_quantity: +this.form.less_quantity,
                minimum: this.product.minimum,
                product: {
                    id: this.product.id,
                    sku: this.product.sku,
                    isbn: this.product.isbn,
                    title: this.product.title,
                    product_id: this.product.id,
                    newProduct: true,
                },
                product_id: this.product.id
            }
            this.$emit('createProduct', payload)
        },
        closePopup() {
            this.$emit('closePopup')
        },
        ...mapActions({
            getProductCategories: 'catalog/getProductCategories',
            getProductsByCategory: 'catalog/getProductsByCategory',
            showProduct: 'catalog/showProduct'
        }),
        // ...mapMutations({
        //     changeLot: 'lot/changeLot'
        // }),
    },
    destroyed() {
        // this.changeLot(null);
    }
}
